.container {
  position: relative;
  background: #00722d;
}

.wave {
  position: absolute;
  height: 75px;
  width: 100%;
  background: #00722d;
  bottom: 0;
}

.wave::before, .wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
}

.wave::before {
  width: 55%;
  height: 109%;
  background-color: #f5f5f5;
  right: -1.5%;
  top: 60%;
}
.wave::after {
  width: 55%;
  height: 100%;
  background-color: #00722d;
  left: -1.5%;
  top: 40%;
}

.container1 {
  background: #00722d;
}

.inversed-container {
  position: relative;
  background: #f5f5f5;
  /*height: 50vh;*/
}

.inversed-wave {
  position: absolute;
  height: 75px;
  width: 100%;
  background: #f5f5f5;
  bottom: 0;
}

.inversed-wave::before, .inversed-wave::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50% 100%;
}

.inversed-wave::after {
  width: 55%;
  height: 100%;
  background-color: #f5f5f5;
  right: -1.5%;
  top: 40%;
}

.inversed-wave::before {
  width: 55%;
  height: 109%;
  background-color: #00722d;
  left: -1.5%;
  top: 60%;
}

.pre-wave {
  height: 75px;
}